<template>
	<el-dialog :title="title" :show-close="showClose" :close-on-click-modal="closeOnClickModal" :visible.sync="dialogVisible" :width="width">
		<slot name="content"></slot>
		<span slot="footer" :style="{'textAlign':align}" class="dialog-footer">
			<slot name="butPre"></slot>
			<el-button class="base_but_style" v-if="cancalShow" @click="cancalFun()">{{cancalText}}</el-button>
			<el-button class="base_but_style base_save_but" v-if="sureShow" type="primary" @click="sureFun()">{{sureText}}</el-button>
			<slot name="butNext"></slot>
		</span>
	</el-dialog>
</template>

<script>
	import { eltips } from '@/util/util.js';
	export default {
		name: 'basemodal',
		props: {
			align:{
				type: String,
				default: 'center'
			},
			title: {
				type: String,
				default: ''
			},
			dialogVisible:{
				type: Boolean,
				default: false
			},
			width:{
				type: String,
				default: '30%'
			},
			'closeOnClickModal':{
				type: Boolean,
				default: false
			},
			'showClose':{
				type: Boolean,
				default: false
			},
			cancalText:{
				type: String,
				default: '取消'
			},
			cancalShow:{
				type: Boolean,
				default: true
			},
			sureText:{
				type: String,
				default: '确认'
			},
			sureShow:{
				type: Boolean,
				default: true
			}
		},
		data() {
			return{
				
			}
		},
		created() {
		},
		methods: {
			cancalFun(){
				this.$emit('cancalFun');
			},
			sureFun(){
				this.$emit('sureFun');
			}
		}
	};
</script>

<style lang="scss" scoped>
	.dialog-footer{
		// border-top: 1px solid #DCDFE6;
		display: block;
		padding-top: 20px;
	}
	::v-deep .el-dialog__body{
		border-top: 1px solid #f1f1f1;
		// border-bottom: 1px solid #f1f1f1;
	}
	::v-deep .el-dialog__header{
		padding-bottom: 20px !important;
	}
	::v-deep .el-dialog__footer{
		padding:0 0 20px 0 !important;
	}
</style>