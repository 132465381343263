import Vue from 'vue'
import ElementUI from 'element-ui';
import '../theme/index.css'
import '../theme/iconfont.css'
import './basic/basic.css'
import './basic/basic.less'
import 'view-design/dist/styles/iview.css';
import App from './App.vue'
import router from './router'
import store from './store'
import Layout from './layout/index.vue'
import './router/permission'
import echarts from 'echarts'
import md5 from 'js-md5';
import axios from 'axios'
import './icons'
import htmlToPdf from "./components/htmlToPdf/htmlToPdf"
Vue.use(htmlToPdf)
import * as util from './util/util.js'
Vue.prototype.$util = util

import VueJsonp from 'vue-jsonp'
Vue.use(VueJsonp)

import plugins from './components/common/index.js'
Vue.use(plugins)

import storeplugins from './components/storeplugins/index.js'
Vue.use(storeplugins)

import retailplugins from './components/retailplugins/index.js'
Vue.use(retailplugins)

import _ from 'lodash'
Vue.prototype._ = _

import Meta from "vue-meta";
Vue.use(Meta);

import Print from 'vue-print-nb'
Vue.use(Print);

// import VueBus from 'vue-bus'
// Vue.use(VueBus);
Vue.prototype.$bus = new Vue({})

import {
	DatePicker
} from 'view-design';
import {
	resetRouter
} from './router/index.js'
// import tim from './util/tim.js'
// import TIM from 'tim-js-sdk/tim-js-friendship.js'
Vue.component('DatePicker', DatePicker);
// Vue.prototype.$tim = tim
// Vue.prototype.TIM = TIM
Vue.prototype.$echarts = echarts
Vue.prototype.$md5 = md5;

      // obj={
      //   class:'弹窗最外面的名称',
      //   title:'弹窗标题',
      //   components:'传入的组件，需要先使用 import test from './components/test.vue' 引入，然后传递 test（components和content只能有一个，并且components里面的关闭和取消按钮需要自己写）'
      //   propsData：组件的传递的参数（components传入的组件哈）
      //   content:'弹窗内容（可以文字、html，也可以展示代码需要`<div></div>`包裹）',
      //   width:'弹窗宽度',
      //   cancelText:'取消按钮的文案，没有传不显示按钮',
      //   confirmText:'确定按钮的文案，没有传不显示按钮',
      //   style:'content内容的基础样式',
      //   showClose:'是否显示弹窗右上角的关闭按钮',
      //   twiceTitle:'确定时的二次确认文字，不传不会二次确定'
      // }
Vue.prototype.$confirmDialog = async function(obj = null) {
if (!obj) return
const idName = 'confirmDialog_new_custom_' + new Date().getTime() + '_' + parseInt(Math.random() * 1000)
const confirmDialog = (await import('@/components/common/baseConfirm.vue')).default
const ConfirmDialogCom = Vue.component('confirmDialog', confirmDialog)// 创建组件
// 给当前组件挂载父级页面的参数(即 main.js里面new Vue() 挂载的额外参数)，这样内部的组件可以直接使用this.$router和this.$store，否则需要单独引入
const componentsArray = ['store', 'router']
const componentsObject = {
  root: this.$root,
  parent: this
}
componentsArray.forEach((item, index) => {
  if (Object.keys(this.$root.$options).indexOf(item) != -1) {
	componentsObject[item] = this[`$${item}`] || null
  } else {
	componentsArray[index] = null
  }
})
// 这里主要是给confirmDialog.vue组件挂载'store', 'router','root','parent',这几个参数，并加载到组件上去，parent就是当前页面的实例
const confirmDialogComNew = new ConfirmDialogCom(componentsObject)// 创建组件实例（可以传递参数 { propsData: props }）

// 将需要重新挂载的参数传到组件（这里传入components时，为了让confirmDialog.vue组件内部再动态添加组件时需要和上面一样挂载的）
confirmDialogComNew.componentsArray = [...['root', 'parent'], ...componentsArray].filter(n => n != null)
const DOM = document.createElement('div')
DOM.setAttribute('class', 'confirmDialog_new_custom')
DOM.setAttribute('id', idName)
document.body.appendChild(DOM)

const comp = confirmDialogComNew.$mount(DOM.appendChild(document.createElement('template')))// 将虚拟dom节点改变成真实dom,获取组件的dom节点,并实现挂载
comp.$watch('centerDialogVisible', (value) => { // 监听组件是否不显示，并删除组件、销毁组件
  if (!value) {
	document.body.removeChild(document.getElementById(idName))
	comp.$destroy()
  }
}, { deep: true })
return comp.showDialog(obj)
}
// Vue.prototype.$bus = new Vue() // event Bus 用于无关系组件间的通信。


Vue.use(ElementUI);

Vue.config.productionTip = false

let baseURL = ''

axios.defaults.baseURL = baseURL //域名:端口’;
// 拦截Axios发起的所有请求，给请求添加token
axios.interceptors.request.use(
	config => {
		const token = sessionStorage.getItem("token");
		if (token) {
			// 判断是否存在token，如果存在的话，则每个http header都加上token
			config.headers["Authorization"] = 'Bearer' + token;
		}
		// if (sessionStorage.getItem("userinfo")) {
		// 	const peripheryAdminUser = (JSON.parse(sessionStorage.getItem("userinfo"))).guard_name;
		// 	config.headers["guardName"] = peripheryAdminUser;
		// }
		// if (sessionStorage.getItem("guardName")) {
		// 	config.headers["guardName"] = sessionStorage.getItem("guardName");
		// }
		if (sessionStorage.getItem("guardName")) {
			config.headers["guardName"] = sessionStorage.getItem("guardName");
		}else{
			config.headers["guardName"] = 'admin_user';
		}
		if (sessionStorage.getItem("shopid")) {
			config.headers["shopId"] = sessionStorage.getItem("shopid");
		}else{
			config.headers["shopId"] = 0;
		}
		return config;
	},
	err => {
		return Promise.reject(err);
	}
);
axios.interceptors.response.use(
	response => {
		//拦截响应，做统一处理 
		if (response.data.code == 777) {
			util.eltips('整站关闭');
			router.push({
				path: "/login"
			})
			resetRouter()
			this.$store.commit('routestatus', 0)
			sessionStorage.removeItem('routestext')
			sessionStorage.removeItem('routes')
			sessionStorage.removeItem('userinfo')
			sessionStorage.removeItem('token')
			sessionStorage.removeItem('isApply')
		} else if (response.data.code == 2001) {
			util.eltips('店铺被禁用');
			router.push({
				path: "/login"
			})
			resetRouter()
			this.$store.commit('routestatus', 0)
			sessionStorage.removeItem('routestext')
			sessionStorage.removeItem('routes')
			sessionStorage.removeItem('userinfo')
			sessionStorage.removeItem('token')
			sessionStorage.removeItem('isApply')
		} else if (response.data.code == 2002) {
			router.push({
				path: "/adminpay"
			})
		}else if (response.data.code == 3001) {
			util.eltips('店铺管理员被禁用');
			router.push({
				path: "/login"
			})
			resetRouter()
			this.$store.commit('routestatus', 0)
			sessionStorage.removeItem('routestext')
			sessionStorage.removeItem('routes')
			sessionStorage.removeItem('userinfo')
			sessionStorage.removeItem('token')
			sessionStorage.removeItem('isApply')
		}else if (response.data.code == 1001) {
			router.push({
				path: "/login"
			})
			resetRouter()
			this.$store.commit('routestatus', 0)
			sessionStorage.removeItem('routestext')
			sessionStorage.removeItem('routes')
			sessionStorage.removeItem('userinfo')
			sessionStorage.removeItem('token')
			sessionStorage.removeItem('isApply')
		}else if (response.data.code == 9999) {
			return "没有权限";
		} else {
			return response.data
		}
	},
)
Vue.prototype.axios = axios
// axios.get("/api/manage/basic/config-index", {}).then((res) => {
axios.get("/api/manage/basic/config-info", {}).then((res) => {
	document.title = res.data.site_title
	let link = res.data.site_icon_url
	let $favicon = document.querySelector('link[rel="icon"]');
	if ($favicon !== null) {
	  $favicon.href = link;
	  $favicon.type = "image/*";
	} else {
	  $favicon = document.createElement("link");
	  $favicon.href = link;
	  $favicon.rel = "icon";
	  $favicon.type = "image/*";
	  document.head.appendChild($favicon);
	}
})
new Vue({
	router,
	store,
	axios,
	render: h => h(Layout)
}).$mount('#app')
