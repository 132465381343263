<template>
  <div class="content" :style="{backgroundColor:plugins_data.background}">
    <div class="vip_code">
      <div class="code" :style="{backgroundColor:plugins_data.btnbackground,color:plugins_data.iconColor}">
        <i class="el-icon-erweima"></i>会员码
      </div>
      <i class="el-icon-setting" :style="{color:plugins_data.iconColor}"></i>
      <i class="el-icon-service" :style="{color:plugins_data.iconColor}"></i>
    </div>
    <div class="vip_info" :style="{backgroundColor:plugins_data.vipbackground}">
      <div class="info_top">
        <div class="names">
          <div class="nickname" :style="{color:plugins_data.mainFontColor}" >{{ "登录/注册" }}</div>
          <div class="vip_tag" :style="{backgroundColor:plugins_data.tagbackground,color:plugins_data.tagcolor}">{{ "普通会员" }}</div>
        </div>
        <div class="avatarbox">
          <img class="avatarimg" src="@/assets/vip/user-avatar.png" />
        </div>
      </div>
      <div class="info_bottom">
        <div class="integral">
          <div class="num" :style="{color:plugins_data.mainFontColor}">0.00</div>
          <div class="text" :style="{color:plugins_data.subFontColor}">收益总额（元）</div>
        </div>
        <div class="filled">
          <div class="num" :style="{color:plugins_data.mainFontColor}">0.00</div>
          <div class="text" :style="{color:plugins_data.subFontColor}">冻结中（元）</div>
        </div>
        <div class="coupon">
          <div class="num" :style="{color:plugins_data.mainFontColor}">0.00</div>
          <div class="text" :style="{color:plugins_data.subFontColor}">可提现（元）</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      plugins_data: {
        type: Object
      }
    },
    created() {
      console.log('%c [ this.plugins_data ]-47', 'font-size:13px; background:#665322; color:#aa9766;', this
        .plugins_data)
    }
  };
</script>

<style lang="scss" scoped>
  .content {
    padding: 20px;
    padding-bottom: 0px;

    .vip_code {
      height: 50px;
      display: flex;
      align-items: center;

      .code {
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 10px;
        color: #fff;

        display: flex;
        align-items: center;

        .el-icon-erweima {
          font-size: 14px;
          margin-right: 5px;
        }
      }

      .el-icon-setting,
      .el-icon-service {
        color: #fff;
        font-size: 20px;
        margin-left: 10px;
      }
    }

    .vip_info {
      padding: 0px 20px;
      padding-bottom: 20px;
      border-radius: 10px 10px 0 0;
      display: flex;
      align-items: center;
      flex-direction: column;

      .info_top {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .names {
          height: 60px;
          display: flex;
          flex-direction: column;

          .nickname {
            flex: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-weight: 600;
          }

          .vip_tag {
            width: fit-content;
            flex: 0 22px;
            padding: 2px 5px;
            font-size: 12px;
            color: #b8b9bd;
            border-radius: 15px;
            background-color: #d7d7d7;
          }
        }

        .avatarbox {
          width: 64px;
          height: 64px;
          overflow: hidden;
          border-radius: 50%;
          border: 2px solid #ffffff;

          display: flex;
          align-items: center;
          justify-content: center;

          position: absolute;
          top: -10px;
          right: 0px;

          .avatarimg {
            width: 64px;
            height: 64px;
          }
        }
      }

      .info_bottom {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .integral,
        .filled,
        .coupon {
          display: flex;
          flex-direction: column;

          .num {
            font-size: 14px;
            font-weight: 600;
          }

          .text {
            font-size: 12px;
            color: #b8b9bd;
          }
        }

        .filled {
          text-align: center;
        }

        .integral {
          .text {
            text-align: left;
          }
        }

        .coupon {
          .num {
            text-align: right;
          }
        }
      }
    }
  }
</style>