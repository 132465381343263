<template>
  <div class="content-box"
    :style="{ 'position': 'absolute',top: '58px',left: 'calc('+left+'px)',width: 'calc(100% - ' + left + 'px)'}">
    <recom></recom>
    <!-- <div class="footbox">
      {{ userinfo.admin_bottom_text || (projectinfo ? projectinfo.technical_support : "") || `Powered by 码扣信息技术开发有限公司 &copy; 2015-${ year > 2015 ? year : 2020 }` }}
    </div> -->
  </div>
</template>
<script>
  import recom from "./recom.vue";
  export default {
    name: "contentBox",
    components: {
      recom
    },
    props: {
      left: {
        type: Number,
        default: 160
      }
    },
    data() {
      return {
        year: new Date().getFullYear(),
        userinfo: {
          admin_bottom_text: ''
        },
        projectinfo: {
          technical_support: ''
        },
      };
    },
    created() {
      this.projectinfo = JSON.parse(sessionStorage.getItem("projectinfo"));
      this.userinfo = JSON.parse(sessionStorage.getItem("userinfo")) || "";
    },
  };
</script>
<style lang="scss" scoped>
  .content-box {
    // width: calc(100vw - 160px);
    height: calc(100vh - 58px);
    background: rgb(245, 247, 250);

    padding: 10px;
    box-sizing: border-box;

    .footbox {
      margin-top: 10px;
      width: 100%;
      text-align: center;
      font-size: 12px;
      line-height: 30px;
      color: #b8b9bd;
    }
  }
</style>