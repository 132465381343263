import Layout from '../layout/components/mainbox.vue'

export const adminroutes = [
	// {
 //        component: "admin/index.vue",
 //        guard_name: "api",
 //        icon: "shouye",
 //        layout: "1",
 //        name: "概况",
 //        path: "/admin/index",
 //        props: null,
 //        router_name: "index",
 //        vue_name: "index",
 //        app_level: 0,
 //        _child: [],
 //    },
    // {
    //     component: "admin/customer.vue",
    //     guard_name: "api",
    //     icon: "yonghu",
    //     layout: "1",
    //     name: "客户",
    //     path: "/admin/customer",
    //     props: null,
    //     router_name: "customer",
    //     vue_name: "customer",
    //     app_level: 0,
    //     _child: [],
    // },
    // {
    //     component: "Layout",
    //     guard_name: "api",
    //     icon: "xiangmu",
    //     layout: "1",
    //     name: "店铺",
    //     path: "",
    //     props: null,
    //     router_name: "project",
    //     vue_name: "project",
    //     app_level: 0,
    //     _child: [{
    //             component: "project/projectdata.vue",
    //             layout: "1",
    //             name: "项目概况",
    //             path: "/project/projectdata",
    //             router_name: "project",
    //             vue_name: "projectdata",
    //             app_level: 0,
    //         },
    //         {
    //             component: "project/project.vue",
    //             layout: "1",
    //             name: "正常进度",
    //             path: "/project/normal",
    //             router_name: "project",
    //             vue_name: "projectnormal",
    //             app_level: 0,
    //             props: 3,
    //         },
    //         {
    //             component: "project/project.vue",
    //             layout: "1",
    //             name: "即将交付",
    //             path: "/project/soon",
    //             router_name: "project",
    //             vue_name: "projectsoon",
    //             app_level: 0,
    //             props: 4,
    //         },
    //         {
    //             component: "project/project.vue",
    //             layout: "1",
    //             name: "超期项目",
    //             path: "/project/overdue",
    //             router_name: "project",
    //             vue_name: "projectoverdue",
    //             app_level: 0,
    //             props: 5,
    //         },
    //         {
    //             component: "project/project.vue",
    //             layout: "1",
    //             name: "交付项目",
    //             path: "/project/deliver",
    //             router_name: "project",
    //             vue_name: "projectdeliver",
    //             app_level: 0,
    //             props: 2,
    //         },
    //         {
    //             component: "project/project.vue",
    //             layout: "1",
    //             name: "解除合作",
    //             path: "/project/relieve",
    //             router_name: "project",
    //             vue_name: "projectrelieve",
    //             app_level: 0,
    //             props: 0,
    //         },
    //         {
    //             component: "project/addproject.vue",
    //             layout: "2",
    //             name: "添加项目",
    //             path: "/project/add",
    //             router_name: "project",
    //             vue_name: "projectadd",
    //             app_level: 0,
    //             props: 6,
    //         },
    //     ],
    // },
    // {
    //     component: "admin/apply.vue",
    //     guard_name: "api",
    //     icon: "yingyong",
    //     layout: "1",
    //     name: "应用",
    //     path: "admin/apply",
    //     props: null,
    //     router_name: "adminapply",
    //     vue_name: "adminapply",
    //     app_level: 0,
    //     _child: []
    // },
    // {
    //     component: "Layout",
    //     guard_name: "api",
    //     icon: "caiwu",
    //     layout: "1",
    //     name: "案例",
    //     path: "",
    //     props: null,
    //     router_name: "finance",
    //     vue_name: "finance",
    //     app_level: 0,
    //     _child: [{
    //             component: "finance/index.vue",
    //             layout: "1",
    //             name: "财务数据",
    //             path: "/finance/index",
    //             router_name: "finance",
    //             vue_name: "financeindex",
    //             app_level: 0,
    //         },
    //         {
    //             component: "finance/profit.vue",
    //             layout: "1",
    //             name: "盈亏数据",
    //             path: "/finance/profit",
    //             router_name: "finance",
    //             vue_name: "financeprofit",
    //             app_level: 0,
    //         },
    //         {
    //             component: "finance/collection.vue",
    //             layout: "1",
    //             name: "收款单",
    //             path: "/finance/collection",
    //             router_name: "finance",
    //             vue_name: "financeCollection",
    //             app_level: 0,
    //         },
    //         {
    //             component: "finance/payment.vue",
    //             layout: "1",
    //             name: "付款单",
    //             path: "/finance/payment",
    //             router_name: "finance",
    //             vue_name: "financePayment",
    //             app_level: 0,
    //         },
    //         {
    //             component: "finance/loan.vue",
    //             layout: "1",
    //             name: "借款单",
    //             path: "/finance/loan",
    //             router_name: "finance",
    //             vue_name: "financeLoan",
    //             app_level: 0,
    //         },
    //         {
    //             component: "finance/repayment.vue",
    //             layout: "1",
    //             name: "还款单",
    //             path: "/finance/repayment",
    //             router_name: "finance",
    //             vue_name: "finanRepayment",
    //             app_level: 0,
    //         },
    //         {
    //             component: "finance/waitCollection.vue",
    //             layout: "1",
    //             name: "待收款",
    //             path: "/finance/waitCollection",
    //             router_name: "finance",
    //             vue_name: "finanWaitCollection",
    //             app_level: 0,
    //         },
    //         {
    //             component: "finance/waitPayment.vue",
    //             layout: "1",
    //             name: "待付款",
    //             path: "/finance/waitPayment",
    //             router_name: "finance",
    //             vue_name: "finanWaitPayment",
    //             app_level: 0,
    //         },
    //     ]
    // },
    // {
    //     component: "applist/apps.vue",
    //     guard_name: "api",
    //     icon: "el-icon-s-platform",
    //     layout: "2",
    //     name: "子应用",
    //     path: "/applist/apps",
    //     props: null,
    //     router_name: "applist",
    //     vue_name: "appchild",
    //     app_level: 0,
    //     _child: []
    // },
    // {
    //     component: "case/case.vue",
    //     guard_name: "api",
    //     icon: "anli",
    //     layout: "1",
    //     name: "案例",
    //     path: "/case",
    //     props: null,
    //     router_name: "case",
    //     vue_name: "case",
    //     app_level: 0,
    //     _child: []
    // },
    // {
    //     component: "case/casedetail.vue",
    //     guard_name: "api",
    //     icon: "el-icon-s-data",
    //     layout: "2",
    //     name: "案例详情",
    //     path: "/case/detail",
    //     props: null,
    //     router_name: "case",
    //     vue_name: "casedetail",
    //     app_level: 0,
    //     _child: []
    // },
    // {
    //     component: "case/addcase.vue",
    //     guard_name: "api",
    //     icon: "el-icon-s-comment",
    //     layout: "2",
    //     name: "添加案例",
    //     path: "/case/add",
    //     props: null,
    //     router_name: "case",
    //     vue_name: "caseadd",
    //     app_level: 0,
    //     _child: []
    // },
    // {
    //     component: "notice/notice.vue",
    //     guard_name: "api",
    //     icon: "gonggao",
    //     layout: "1",
    //     name: "公告",
    //     path: "/notice",
    //     props: null,
    //     router_name: "notice",
    //     vue_name: "notice",
    //     app_level: 0,
    //     _child: []
    // },
    // {
    //     component: "notice/addnotice.vue",
    //     guard_name: "api",
    //     icon: "el-icon-s-comment",
    //     layout: "2",
    //     name: "添加公告",
    //     path: "/notice/add",
    //     props: null,
    //     router_name: "notice",
    //     vue_name: "noticeadd",
    //     app_level: 0,
    //     _child: []
    // },
    // {
    //     component: "notice/noticedetail.vue",
    //     guard_name: "api",
    //     icon: "el-icon-s-comment",
    //     layout: "2",
    //     name: "公告详情",
    //     path: "/notice/detail",
    //     props: null,
    //     router_name: "notice",
    //     vue_name: "noticedetail",
    //     app_level: 0,
    //     _child: []
    // },
    // {
    // 	component: "Layout",
    // 	guard_name: "api",
    // 	icon: "zhinan",
    // 	layout: "1",
    // 	name: "指南",
    // 	path: "",
    // 	props: null,
    // 	router_name: "help",
    // 	vue_name: "help",
    // 	app_level:0,
    // 	_child: [
    // 		{
    // 			component: "help/help.vue",
    // 			layout: "1",
    // 			name: "添加分类",
    // 			path: "/help/addclass",
    // 			router_name: "help",
    // 			vue_name: "helpaddclass",
    // 			app_level:0,
    // 		},
    // 		{
    // 			component: "help/addhelp.vue",
    // 			layout: "1",
    // 			name: "添加指南",
    // 			path: "/help/add",
    // 			router_name: "help",
    // 			vue_name: "helpadd",
    // 			app_level:0,
    // 		},
    // 		{
    // 			component: "help/helplist.vue",
    // 			layout: "1",
    // 			name: "指南列表",
    // 			path: "/help/list",
    // 			router_name: "help",
    // 			vue_name: "helplist",
    // 			app_level:0,
    // 		}
    // 	]	
    // },
    // {
    //     component: "Layout",
    //     guard_name: "api",
    //     icon: "qudao",
    //     layout: "1",
    //     name: "代理",
    //     path: "",
    //     props: null,
    //     router_name: "agent",
    //     vue_name: "agent",
    //     app_level: 0,
    //     _child: [{
    //             component: "agent/list.vue",
    //             guard_name: "api",
    //             icon: null,
    //             layout: "1",
    //             name: "代理管理",
    //             path: "/agent/list",
    //             props: null,
    //             router_name: "agent",
    //             vue_name: "agentList",
    //             app_level: 0,
    //             _child: []
    //         },
    //         {
    //             component: "agent/add.vue",
    //             guard_name: "api",
    //             icon: null,
    //             layout: "2",
    //             name: "添加代理",
    //             path: "/agent/add",
    //             props: null,
    //             router_name: "agent",
    //             vue_name: "agentAdd",
    //             app_level: 0,
    //             _child: []
    //         },
    //         {
    //             component: "agent/recharge.vue",
    //             guard_name: "api",
    //             icon: null,
    //             layout: "1",
    //             name: "充值记录",
    //             path: "/agent/recharge",
    //             props: null,
    //             router_name: "agent",
    //             vue_name: "agentRecharge",
    //             app_level: 0,
    //             _child: []
    //         },
    //         // {
    //         // 	component: "agent/consumption.vue",
    //         // 	guard_name: "api",
    //         // 	icon: null,
    //         // 	layout: "1",
    //         // 	name: "消费记录",
    //         // 	path: "/agent/consumption",
    //         // 	props: null,
    //         // 	router_name: "agent",
    //         // 	vue_name: "agentConsumption",
    //         // 	app_level:0,
    //         // 	_child: []
    //         // },
    //         {
    //             component: "Layout",
    //             guard_name: "api",
    //             icon: null,
    //             layout: "1",
    //             name: "提现管理",
    //             path: "",
    //             props: null,
    //             router_name: "agent",
    //             vue_name: "withdrawal",
    //             app_level: 0,
    //             _child: [{
    //                     component: "agent/withdrawal.vue",
    //                     guard_name: "api",
    //                     icon: null,
    //                     layout: "1",
    //                     name: "待审核",
    //                     path: "/withdrawal/wait",
    //                     props: 0,
    //                     router_name: "agent",
    //                     vue_name: "withdrawalWait",
    //                     app_level: 0,
    //                     _child: []
    //                 },
    //                 {
    //                     component: "agent/withdrawal.vue",
    //                     guard_name: "api",
    //                     icon: null,
    //                     layout: "1",
    //                     name: "已通过",
    //                     path: "/withdrawal/adopt",
    //                     props: 1,
    //                     router_name: "agent",
    //                     vue_name: "withdrawalWait",
    //                     app_level: 0,
    //                     _child: []
    //                 },
    //                 {
    //                     component: "agent/withdrawal.vue",
    //                     guard_name: "api",
    //                     icon: null,
    //                     layout: "1",
    //                     name: "未通过",
    //                     path: "/withdrawal/refuse",
    //                     props: 2,
    //                     router_name: "agent",
    //                     vue_name: "withdrawalWait",
    //                     app_level: 0,
    //                     _child: []
    //                 },
    //                 {
    //                     component: "agent/withdrawal.vue",
    //                     guard_name: "api",
    //                     icon: null,
    //                     layout: "1",
    //                     name: "已打款",
    //                     path: "/withdrawal/complete",
    //                     props: 3,
    //                     router_name: "agent",
    //                     vue_name: "withdrawalWait",
    //                     app_level: 0,
    //                     _child: []
    //                 }
    //             ]
    //         },
    //         {
    //             component: "agent/set.vue",
    //             guard_name: "api",
    //             icon: null,
    //             layout: "1",
    //             name: "基础设置",
    //             path: "/agent/set",
    //             props: null,
    //             router_name: "agent",
    //             vue_name: "agentSet",
    //             app_level: 0,
    //             _child: []
    //         },
    //     ]
    // },
    // {
    //     component: "agent/data.vue",
    //     guard_name: "api",
    //     icon: "gaikuang",
    //     layout: "1",
    //     name: "数据",
    //     path: "/data/data",
    //     props: null,
    //     router_name: "data",
    //     vue_name: "data",
    //     app_level: 0,
    //     _child: []
    // },

    // {
    //     component: "applist/appsmenu.vue",
    //     guard_name: "api",
    //     icon: "el-icon-s-platform",
    //     layout: "2",
    //     name: "应用菜单",
    //     path: "/applist/appsmenu",
    //     props: null,
    //     router_name: "applist",
    //     vue_name: "appsmenu",
    //     app_level: 0,
    //     _child: []
    // },
   //  {
   //      component: "Layout",
   //      guard_name: "api",
   //      icon: "shezhi",
   //      layout: "1",
   //      name: "设置",
   //      path: "",
   //      props: null,
   //      router_name: "account",
   //      vue_name: "account",
   //      app_level: 0,
   //      _child: [
			// {
			//     component: "Layout",
			//     guard_name: "api",
			//     icon: null,
			//     layout: "1",
			//     name: "系统设置",
			//     path: "",
			//     props: null,
			//     router_name: "account",
			//     vue_name: "systemset",
			//     app_level: 0,
			//     _child: [{
			//             component: "admin/system/menu.vue",
			//             guard_name: "api",
			//             icon: null,
			//             layout: "1",
			//             name: "菜单管理",
			//             path: "/admin/system/menu",
			//             props: 0,
			//             router_name: "adminsystemmenu",
			//             vue_name: "adminsystemmenu",
			//             app_level: 0,
			//             _child: []
			//         },
			// 		{
			// 		    component: "admin/system/menu_add.vue",
			// 		    guard_name: "api",
			// 		    icon: null,
			// 		    layout: "2",
			// 		    name: "菜单编辑添加",
			// 		    path: "/admin/system/menu_add",
			// 		    props: 1,
			// 		    router_name: "adminsystemmenu_add",
			// 		    vue_name: "adminsystemmenu_add",
			// 		    app_level: 0,
			// 		    _child: []
			// 		},
			//         {
			//             component: "admin/system/role.vue",
			//             guard_name: "api",
			//             icon: null,
			//             layout: "1",
			//             name: "角色管理",
			//             path: "/admin/system/role",
			//             props: 1,
			//             router_name: "adminsystemrole",
			//             vue_name: "adminsystemrole",
			//             app_level: 0,
			//             _child: []
			//         },
			// 		{
			// 		    component: "admin/system/role_add.vue",
			// 		    guard_name: "api",
			// 		    icon: null,
			// 		    layout: "2",
			// 		    name: "角色编辑添加",
			// 		    path: "/admin/system/role_add",
			// 		    props: 1,
			// 		    router_name: "adminsystemrole_add",
			// 		    vue_name: "adminsystemrole_add",
			// 		    app_level: 0,
			// 		    _child: []
			// 		},
			// 		{
			// 		    component: "admin/system/user.vue",
			// 		    guard_name: "api",
			// 		    icon: null,
			// 		    layout: "1",
			// 		    name: "管理员管理",
			// 		    path: "/admin/system/user",
			// 		    props: 1,
			// 		    router_name: "adminsystemuser",
			// 		    vue_name: "adminsystemuser",
			// 		    app_level: 0,
			// 		    _child: []
			// 		},
			// 		{
			// 		    component: "admin/system/user_add.vue",
			// 		    guard_name: "api",
			// 		    icon: null,
			// 		    layout: "2",
			// 		    name: "管理员编辑添加",
			// 		    path: "/admin/system/user_add",
			// 		    props: 1,
			// 		    router_name: "adminsystemuser_add",
			// 		    vue_name: "adminsystemuser_add",
			// 		    app_level: 0,
			// 		    _child: []
			// 		},
			//     ]
			// },
			// {
			//     component: "Layout",
			//     guard_name: "api",
			//     icon: null,
			//     layout: "1",
			//     name: "基础设置",
			//     path: "",
			//     props: null,
			//     router_name: "account",
			//     vue_name: "withdrawal",
			//     app_level: 0,
			//     _child: [{
			//             component: "admin/set/basics.vue",
			//             guard_name: "api",
			//             icon: null,
			//             layout: "1",
			//             name: "站点设置",
			//             path: "/admin/set/site",
			//             props: 0,
			//             router_name: "account",
			//             vue_name: "adminsetsite",
			//             app_level: 0,
			//             _child: []
			//         },
			//         {
			//             component: "admin/set/agreement.vue",
			//             guard_name: "api",
			//             icon: null,
			//             layout: "1",
			//             name: "店铺协议",
			//             path: "/admin/set/agreement",
			//             props: 1,
			//             router_name: "account",
			//             vue_name: "adminsetagreement",
			//             app_level: 0,
			//             _child: []
			//         },
			//     ]
			// }
   //      ]
   //  },
    // {
    //     component: "Layout",
    //     guard_name: "api",
    //     icon: "shezhi",
    //     layout: "1",
    //     name: "配送",
    //     path: "",
    //     props: null,
    //     router_name: "delivery",
    //     vue_name: "delivery",
    //     app_level: 0,
    //     _child: [{
    //             component: "delivery/storemanage.vue",
    //             guard_name: "api",
    //             icon: null,
    //             layout: "1",
    //             name: "门店管理",
    //             path: "/delivery/storemanage",
    //             props: null,
    //             router_name: "delivery",
    //             vue_name: "storemanage",
    //             app_level: 0,
    //             _child: []
    //         },
    //         {
    //             component: "delivery/financemanage.vue",
    //             guard_name: "api",
    //             icon: null,
    //             layout: "1",
    //             name: "财务管理",
    //             path: "/delivery/financemanage",
    //             props: null,
    //             router_name: "delivery",
    //             vue_name: "financemanage",
    //             app_level: 0,
    //             _child: []
    //         },
    //         {
    //             component: "delivery/basics.vue",
    //             guard_name: "api",
    //             icon: null,
    //             layout: "1",
    //             name: "基础设置",
    //             path: "/delivery/basics",
    //             props: null,
    //             router_name: "delivery",
    //             vue_name: "deliverybasics",
    //             app_level: 0,
    //             _child: []
    //         }
    //     ]
    // },
    
    // {
    //     component: "Layout",
    //     guard_name: "api",
    //     icon: "shezhi",
    //     layout: "1",
    //     name: "流量主",
    //     path: "",
    //     props: null,
    //     router_name: "flow",
    //     vue_name: "flow",
    //     app_level: 0,
    //     _child: [{
    //             component: "flow/minipmanage.vue",
    //             guard_name: "api",
    //             icon: null,
    //             layout: "1",
    //             name: "小程序管理",
    //             path: "/flow/minipmanage",
    //             props: null,
    //             router_name: "flow",
    //             vue_name: "minipmanage",
    //             app_level: 0,
    //             _child: []
    //         },
    //         {
    //             component: "flow/basics.vue",
    //             guard_name: "api",
    //             icon: null,
    //             layout: "1",
    //             name: "基础设置",
    //             path: "/flow/basics",
    //             props: null,
    //             router_name: "flow",
    //             vue_name: "flowbasics",
    //             app_level: 0,
    //             _child: []
    //         },
    //         {
    //             component: "flow/ad.vue",
    //             guard_name: "api",
    //             icon: null,
    //             layout: "2",
    //             name: "广告管理",
    //             path: "/flow/minipmanage/ad",
    //             props: null,
    //             router_name: "miniAd",
    //             vue_name: "miniAd",
    //             app_level: 0,
    //             _child: []
    //         }
    //     ]
    // }
]