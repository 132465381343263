<template>
  <div class="plugins">
    <div class="editbox">
      <div class="box">
        <div class="label">页面背景:</div>
        <el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.background">
        </el-color-picker>
        <el-input v-model="plugins_data.background" style="width: 100px;margin-right: 6px;"></el-input>
        <el-button plain size="small" @click="plugins_data.background = '#FF8A00'">重置</el-button>
      </div>
      <div class="box">
        <div class="label">按钮背景:</div>
        <el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.btnbackground">
        </el-color-picker>
        <el-input v-model="plugins_data.btnbackground" style="width: 100px;margin-right: 6px;">
        </el-input>
        <el-button plain size="small" @click="plugins_data.btnbackground = '#FFA133'">重置</el-button>
      </div>
      <div class="box">
        <div class="label">详情背景:</div>
        <el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.vipbackground">
        </el-color-picker>
        <el-input v-model="plugins_data.vipbackground" style="width: 100px;margin-right: 6px;">
        </el-input>
        <el-button plain size="small" @click="plugins_data.vipbackground = '#EDEEEF'">重置</el-button>
      </div>
      <div class="box">
        <div class="label">标签背景:</div>
        <el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.tagbackground">
        </el-color-picker>
        <el-input v-model="plugins_data.tagbackground" style="width: 100px;margin-right: 6px;">
        </el-input>
        <el-button plain size="small" @click="plugins_data.tagbackground = '#D7D7D7'">重置</el-button>
      </div>
      <div class="box">
        <div class="label">标签字体:</div>
        <el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.tagcolor">
        </el-color-picker>
        <el-input v-model="plugins_data.tagcolor" style="width: 100px;margin-right: 6px;">
        </el-input>
        <el-button plain size="small" @click="plugins_data.tagcolor = '#B8B9BD'">重置</el-button>
      </div>
      <div class="box">
        <div class="label">主字体颜色:</div>
        <el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.mainFontColor">
        </el-color-picker>
        <el-input v-model="plugins_data.mainFontColor" style="width: 100px;margin-right: 6px;">
        </el-input>
        <el-button plain size="small" @click="plugins_data.mainFontColor = '#000000'">重置</el-button>
      </div>
      <div class="box">
        <div class="label">副字体颜色:</div>
        <el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.subFontColor">
        </el-color-picker>
        <el-input v-model="plugins_data.subFontColor" style="width: 100px;margin-right: 6px;">
        </el-input>
        <el-button plain size="small" @click="plugins_data.subFontColor = '#B8B9BD'">重置</el-button>
      </div>
      <div class="box">
        <div class="label">图标颜色:</div>
        <el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.iconColor">
        </el-color-picker>
        <el-input v-model="plugins_data.iconColor" style="width: 100px;margin-right: 6px;">
        </el-input>
        <el-button plain size="small" @click="plugins_data.iconColor = '#FFFFFF'">重置</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';
  export default {
    name: "noticeData",
    data() {
      return {};
    },
    props: {
      plugins_data: {
        type: Object
      },
    },
    methods: {},
  };
</script>
<style lang="less" scoped>
  @import "../../style/pluginsCommon.css";

  .editbox {
    padding: 16px;

    .box {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .label {
        width: 80px;
        margin-right: 10px;
      }
    }
  }
</style>